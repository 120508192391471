<template lang="html">
  <div id="access-page">

    <section class="main-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-lg-9 col-xl-7 col-content">
            <div class="box-logo">
              <img src="public/images/logo.svg" alt="G Capital Group">
            </div>

            <div class="box-form">
              <h4 class="pb-2 mb-4 text-center">Restaurar mi contraseña</h4>

              <div class="d-block pb-1 mb-4 text-center txt-l-h-1-3">
                <p>
                  Escribe el correo electronico asociado a tu cuenta. Se le enviará un mensaje para restaurar tu contraseña.
                </p>
              </div>

              <b-form @submit="onSubmit">
                <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                  <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
                </b-form-group>

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-6">
                    </div>

                    <div class="mt-4 mt-sm-0 col-12 text-center text-sm-right order-5 order-sm-0">
                      <router-link to="/">Si recuerdo mi contraseña <i class="fas fa-user-alt ml-1 small"></i></router-link>
                    </div>

                    <div class="col-12 mt-3 text-center">
                      <b-button type="submit" class="btn-s1 bg-white">Restaurar contraseña</b-button>
                    </div>

                    <div class="col-12 mt-2 mt-sm-3 text-center order-6">
                      <p>¿No tienes acceso? <router-link class="f-w-600" to="/registrarse">Obtén acceso</router-link></p>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
