<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Cambiar contraseña</h2>
        </div>
      </div>
    </section>

    <section class="account-section">
      <div class="box box-white-s1">
        <b-form @submit="onSubmit">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Contraseña actual">
                <b-form-input type="password" v-model="form.oldpass" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Nueva contraseña">
                <b-form-input type="password" v-model="form.pass" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Confirmar contraseña">
                <b-form-input type="password" v-model="form.copass" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 bg-black">Cambiar contraseña</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario  ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Enviar...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Mi cuenta';
  }
}
</script>
