import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'access/login.vue').default, meta: { title:"Accede a tu cuenta" }},
      { path: '/restaurar-contrasena', component: require(page+'access/recover-password.vue').default, meta: { title:"Restaurar contraseña" }},
      { path: '/registrarse', component: require(page+'access/register.vue').default, meta: { title:"Registrarse" }},
      { path: '/autenticacion', name: 'authPage', component: require(page+'access/2fa-authentication.vue').default, meta: { title:"Accede a tu cuenta" }},

      { path: '/dashboard', component: require(page+'user-account/index.vue').default, meta: { title:"Dashboard" },
        children: [
          { path: '/', name: 'dashboardPage', component: require(page+'user-account/home/index.vue').default, meta:{ title: 'Dashboard' } },

          { path: 'mi-cuenta', name: 'accMyAccountPage', component: require(page+'user-account/account/my-account.vue').default, meta:{ title: 'Mi cuenta' } },
          { path: 'cambiar-imagen', name: 'accChangeImagePage', component: require(page+'user-account/account/image.vue').default, meta:{ title: 'Cambiar imagen' } },
          { path: 'cambiar-contrasena', name: 'accChangePasswordPage', component: require(page+'user-account/account/password.vue').default, meta:{ title: 'Cambiar contraseña' } },

          { path: 'inversiones-financieras', name: 'invFinanPage', component: require(page+'user-account/patrimonio/inversiones-financieras.vue').default, meta:{ title: 'Inversiones financieras' } },
          { path: 'empresas', name: 'empresasPage', component: require(page+'user-account/patrimonio/empresas.vue').default, meta:{ title: 'Empresas' } },
          { path: 'fideicomisos', name: 'fideicomisosPage', component: require(page+'user-account/patrimonio/fideicomisos.vue').default, meta:{ title: 'Fideicomisos' } },
          { path: 'bienes-raices', name: 'bienesRaicesPage', component: require(page+'user-account/patrimonio/bienes-raices.vue').default, meta:{ title: 'Bienes raices' } },
          { path: 'bienes-raices/:id', name: 'bienesRaicesDetailPage', component: require(page+'user-account/patrimonio/bienes-raices-detail.vue').default, meta:{ title: 'Bienes raices' } },
          { path: 'activos-personales', name: 'actPersonalesPage', component: require(page+'user-account/patrimonio/activos-personales.vue').default, meta:{ title: 'Activos personales' } },
          { path: 'avales', name: 'avalesPage', component: require(page+'user-account/patrimonio/avales.vue').default, meta:{ title: 'Avales' } },

          { path: 'sistema-financiero', name: 'sisFinancieroPage', component: require(page+'user-account/pasivos/sistema-financiero.vue').default, meta:{ title: 'Sistema financiero' } },
          { path: 'pasivos-personales', name: 'pasPersonalesPage', component: require(page+'user-account/pasivos/personales.vue').default, meta:{ title: 'Pasivos personales' } },
        ]
      },

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}

});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "G Capital Group", filter: (title)=>{ return title+" - G Capital Group"; } }
);

// export {routes};
export default MyRouter;
