<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Empresas</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 outline-black mr-2">Exportar</button>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="box-white-s1 results-section">
      <div class="row">
        <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option value="a">Mayor a menor</b-form-select-option>
                <b-form-select-option value="b">Menor a mayor</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div>

        <div class="col-lg-12 content-sample-1" v-for="(c, cInx) in companies" :key="'cInx-'+cInx">
          <div class="box">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+c.imgUrl+')' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="name">{{ c.title }}</h5>
                    <h6 class="subtitle">{{ c.url }}</h6>
                  </div>

                  <div class="col box-tab">
                    <span :class="'tab '+c.compType">{{ c.compName }}</span>
                  </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Giro: IT</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Ventas: $8,0000,000.00</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Acciones: 123</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>País: México</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Crecimiento: 1.9%</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Valor invertido: $5,200.00</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Valor: $10,0000,000.00</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Utilidad: 30%</h5>
                </div>

                <div class="col-12 col-xl-4 col-txt text-center text-xl-right">
                  <router-link class="_link" to="">Ver acciones</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,

      companies: [
        { imgUrl: 'public/images/pages/account/company-1.jpg', title: 'Sustam S.A. de C.V.', url: 'sustam.com', compType: 'tab-1', compName: 'Empresa propia' },
        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },

        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },
        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },
      ]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
