<template lang="html">
  <div id="access-page">

    <section class="main-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-lg-9 col-xl-7 col-content">
            <div class="box-logo">
              <img src="public/images/logo.svg" alt="G Capital Group">
            </div>

            <div class="box-form">
              <h4 class="pb-2 mb-4 text-center">Ingresa el código de seguridad enviado al número con terminación **** 8389</h4>

              <b-form @submit="onSubmit">
                <div class="box-2fa">
                  <div class="bx" v-bind:class="{ focused : isFocused }">
                    <b-form-input required type="number" v-model="form.code" @keypress="isNumber($event)" @paste="noPaste" @keydown="limitChars" ref="inputCode"></b-form-input>

                    <div class="box-number" v-bind:class="{ active : splittedNumber[0] }">{{ splittedNumber[0] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[1] }">{{ splittedNumber[1] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[2] }">{{ splittedNumber[2] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[3] }">{{ splittedNumber[3] }}</div>

                    <button class="btn-focus" @click="focusOnInput" @mouseleave="unfocus"></button>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="mt-4 col-12 text-center">
                      <p>
                        ¿No recibiste el código? <a class="c-pointer f-w-600">Reenviar código</a>
                      </p>
                    </div>

                    <div class="col-12 mt-3 text-center">
                      <b-button type="submit" class="btn-s1 bg-white">Entrar</b-button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        code: '',
      },

      isFocused: false,
      splittedNumber: []
    }
  },

  methods: {
    noPaste(event){
      event.preventDefault();
    },

    isNumber: function(event) {
      event = (event) ? event : window.event;
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    limitChars(event) {
      var key = event.keyCode || event.charCode;
      // console.log(key);
      if(key !== 8 && key !== 46) { // Allow backspace & delete buttons
        if(key >= 37 && key <= 40) { // Block navigaton buttons
          event.preventDefault();
        }else {
          if ( this.form.code.length >= 4 ) { // Allow only 4 digits
            event.preventDefault();
          }
        }
      }
    },

    focusOnInput() {
      this.isFocused = true;
      this.$refs.inputCode.focus();
    },

    unfocus() {
      this.isFocused = false;
    },

    onSubmit(event) {
      event.preventDefault();

      if(this.form.code.length == 4) {
        this.$router.push('/dashboard');
      }
    },
  },

  watch: {
    'form.code'(val, oldVal){
      this.splittedNumber = val.split("");
    }
  }
}
</script>
