<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Fideicomisos</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="fideicomisos-tabs categories-tabs-section">
      <div class="box-tabs">
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 0 }" @click="category = 0">Fideicomisos Patrimoniales</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 1 }" @click="category = 1">Fideicomisos de Acciones</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 2 }" @click="category = 2">Fideicomisos de Activos</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 3 }" @click="category = 3">Fideicomisos de Garantía</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 5 }" @click="category = 5">Otro</button>
        </div>
      </div>
    </section>

    <section class="box-white-s1 results-section">
      <div class="row">
        <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option :value="null">Nombre ascendente</b-form-select-option>
                <b-form-select-option value="a">Nombre descendente</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div>

        <div class="col-lg-6 col-xl-4 content-sample-1" v-for="(c, cInx) in companies" :key="'cInx-'+cInx">
          <div class="box">
            <div class="col col-info col-fideicomiso">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Beneficiario</h6>
                    <h5 class="name">{{ c.title }}</h5>
                  </div>
                </div>

                <div class="col-12 col-txt">
                  <h5> ID: <strong>348150165</strong></h5>
                </div>

                <div class="col-12 col-txt">
                  <h5>USD | Fiduciario</h5>
                </div>

                <div class="col-12 col-txt">
                  <h5 class="f-w-600">$100,000.00</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,
      category: 0,

      companies: [
        { title: 'Juan Cruz Hernandez' },
        { title: 'Maria Eugenia Corona' },
        { title: 'Elena Santa Cruz' },
        { title: 'Juan Cruz Hernandez' },
        { title: 'Maria Eugenia Corona' },
        { title: 'Elena Santa Cruz' },
        { title: 'Juan Cruz Hernandez' },
        { title: 'Maria Eugenia Corona' },
        { title: 'Elena Santa Cruz' },
        { title: 'Juan Cruz Hernandez' },
        { title: 'Maria Eugenia Corona' },
        { title: 'Elena Santa Cruz' },
      ]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
