<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Avales</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="results-section">
      <b-card class="card-tabs-s1" no-body>
        <b-tabs fill>
          <b-tab title="Aval 1" active>
            <div class="_tab-content">
              <div class="row">
                <div class="col-lg-12 col-info">
                  <h4 class="mb-2 tab-title">Ana Luz Pérez López</h4>

                  <!-- Table -->
                  <div class="fake-table">
                    <div class="t-row">
                      <div class="th">Tipo de contrato</div>
                      <div class="td">Renta de departamento</div>
                    </div>
                    <div class="t-row">
                      <div class="th">Pagaré</div>
                      <div class="td">
                        <a href="">Ver pagare</a>
                      </div>
                    </div>
                    <div class="t-row">
                      <div class="th">Vigencia</div>
                      <div class="td">17 de Noviembre del 2023</div>
                    </div>
                    <div class="t-row">
                      <div class="th">Saldo</div>
                      <div class="td">$5,300.00</div>
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Aval 2">
            <b-card-text>Pestaña 2</b-card-text>
          </b-tab>
          <b-tab title="Aval 3">
            <b-card-text>Pestaña 3</b-card-text>
          </b-tab>
          <b-tab title="Aval 4">
            <b-card-text>Pestaña 4</b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Graficos ==
      data: [
        {
          "group": "Rendimiento",
          "key": "Enero",
          "value": 34200
        },
        {
          "group": "Rendimiento",
          "key": "Febrero",
          "value": 23500
        },
        {
          "group": "Rendimiento",
          "key": "Marzo",
          "value": 53100
        },
        {
          "group": "Rendimiento",
          "key": "Abril",
          "value": 42300
        },
        {
          "group": "Rendimiento",
          "key": "Mayo",
          "value": 32300
        },
        {
          "group": "Rendimiento",
          "key": "Junio",
          "value": 43300
        },
      ],

      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Meses",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "mapsTo": "value",
            "title": "Cantidad",
            "scaleType": "linear"
          }
        },
        "height": "400px",
      }
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
