<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Inversiones financieras</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <div class="mr-2 box-total-s1">
            <h6>Total de todas las cuentas</h6>
            <h5>$7,000,000.00 MXN</h5>
          </div>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="results-section">
      <b-card class="card-tabs-s1" no-body>
        <b-tabs fill>
          <b-tab title="Productiva Banorte" active>
            <div class="_tab-content">
              <div class="row">
                <div class="col-lg-6 col-table">
                  <div class="box-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" class="c-title">Resumen del portafolio</th>
                          <th scope="col" class="c-currency">
                            <div class="box-currency-s1">
                              <span v-bind:class="{ active : currency == 'mxn' }" @click="currency = 'mxn'">MXN</span>
                              <span v-bind:class="{ active : currency == 'usd' }" @click="currency = 'usd'">USD</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Resumen del portafolio</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Saldo inicial del periodo</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Entradas efectivo/valores</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Saldo efectivo/valores</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Plusvalía / Minusvalía</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Saldo final</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                        <tr>
                          <th>Rendmiento</th>
                          <td class="n">$100,000.00 MXN</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-6 col-charts">
                  <div class="box-title">
                    <h5>Rendimiento conforme al periodo anterior</h5>
                  </div>

                  <div class="box-content">
                    <ccv-line-chart :data='data' :options='options'></ccv-line-chart>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Inverlat">
            <b-card-text>Pestaña 2</b-card-text>
          </b-tab>
          <b-tab title="UBS">
            <b-card-text>Pestaña 3</b-card-text>
          </b-tab>
          <b-tab title="CITI">
            <b-card-text>Pestaña 4</b-card-text>
          </b-tab>
          <b-tab title="CHASE">
            <b-card-text>Pestaña 5</b-card-text>
          </b-tab>
          <b-tab title="SCOTIA">
            <b-card-text>Pestaña 6</b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Graficos ==
      data: [
        {
          "group": "Rendimiento",
          "key": "Enero",
          "value": 34200
        },
        {
          "group": "Rendimiento",
          "key": "Febrero",
          "value": 23500
        },
        {
          "group": "Rendimiento",
          "key": "Marzo",
          "value": 53100
        },
        {
          "group": "Rendimiento",
          "key": "Abril",
          "value": 42300
        },
        {
          "group": "Rendimiento",
          "key": "Mayo",
          "value": 32300
        },
        {
          "group": "Rendimiento",
          "key": "Junio",
          "value": 43300
        },
      ],

      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Meses",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "mapsTo": "value",
            "title": "Cantidad",
            "scaleType": "linear"
          }
        },
        "height": "400px",
      }
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
