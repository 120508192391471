<template lang="html">
  <footer id="d-footer">
		<div class="row no-gutters">
      <div class="offset-md-3 col-md-6">
        <p>Plataforma Family Office© 2023</p>
      </div>

      <div class="col-md-3 col-links">
        <p>
          <router-link class="mx-2" to=""><u>Ayuda</u></router-link>
          <router-link class="mx-2" to=""><u>Seguridad</u></router-link>
        </p>
      </div>
    </div>
	</footer>
</template>

<script>
export default {
}
</script>
