<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Sistema financiero</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="box-white-s1 results-section">
      <div class="row">
        <div class="col-lg-12 content-sample-1" v-for="(c, cInx) in companies" :key="'cInx-'+cInx">
          <div class="box">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+c.imgUrl+')' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="d-block mb-2 name">{{ c.title }}</h5>
                    <h6 class="d-block subtitle">{{ c.subtitle }}</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Disponible: $8,0000,000.00</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Tasa: 19.9%</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Consumido: $10,000.00</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Vencimiento: 30/Dic/2022</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,

      companies: [
        { imgUrl: 'public/images/pages/account/bank-1.jpg', title: 'Citibanamex', subtitle: 'Linea de crédito', compType: 'tab-1', compName: 'Empresa propia' },
        { imgUrl: 'public/images/pages/account/bank-2.jpg', title: 'Banorte', subtitle: 'Prestamo', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/bank-3.jpg', title: 'Santander', subtitle: 'Arrendamiento', compType: 'tab-3', compName: 'Inversionista minorista' },
      ]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Pasivos';
  }
}
</script>
