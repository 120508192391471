<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Cambiar datos</h2>
        </div>
      </div>
    </section>

    <section class="account-section">
      <div class="box box-white-s1">
        <b-form @submit="onSubmit">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Nombre completo">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Dirección">
                <b-form-input type="text" v-model="form.address" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Colonia">
                <b-form-input type="text" v-model="form.col" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Teléfono">
                <b-form-input type="text" v-model="form.tel" required minlength="10" maxlength="10" placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 bg-black">Cambiar datos</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario  ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Enviar...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Mi cuenta';
  }
}
</script>
