<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Bienes raíces</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 outline-black mr-2">Exportar</button>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="categories-tabs-section">
      <div class="box-tabs">
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 0 }" @click="category = 0">Ver todas</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 1 }" @click="category = 1">Habitacional</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 2 }" @click="category = 2">Comercial</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 3 }" @click="category = 3">Industrial</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 4 }" @click="category = 4">Vacacional</button>
        </div>
        <div class="_tab">
          <button class="btn-cat" v-bind:class="{ active : category == 5 }" @click="category = 5">Otro</button>
        </div>
      </div>
    </section>

    <section class="box-white-s1 results-section">
      <div class="row">
        <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Nombre ascendente</b-form-select-option>
                <b-form-select-option value="a">Nombre descendente</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div>

        <div class="col-xl-6 content-sample-1" v-for="(c, cInx) in companies" :key="'cInx-'+cInx">
          <router-link class="box" :to="{ name: 'bienesRaicesDetailPage', params: { id: 1 } }">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+c.imgUrl+')' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="mb-2 name">{{ c.title }}</h5>

                    <p class="descr">
                      Diamante #2680, Bosques de la Victoria, Zapopan, Jalisco.
                    </p>
                  </div>
                </div>

                <div class="col-6 col-txt">
                  <h5 class="small">
                    Estado:
                    <strong class="d-block">En venta</strong>
                  </h5>
                </div>

                <div class="col-6 col-txt">
                  <h5 class="small">
                    Dividendo:
                    <strong class="d-block">$100,000.00</strong>
                  </h5>
                </div>

                <div class="col-6 col-txt">
                  <h5 class="small">
                    Valor catastral:
                    <strong class="d-block">En venta</strong>
                  </h5>
                </div>

                <div class="col-6 col-txt">
                  <h5 class="small">
                    Valor comercial:
                    <strong class="d-block">$1,000,000.00</strong>
                  </h5>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,
      category: 0,

      companies: [
        { imgUrl: 'public/images/pages/account/location-1.jpg', title: 'Nombre de edificio o casa' },
        { imgUrl: 'public/images/pages/account/location-2.jpg', title: 'Nombre de edificio o casa' },
        { imgUrl: 'public/images/pages/account/location-3.jpg', title: 'Nombre de edificio o casa' },
        { imgUrl: 'public/images/pages/account/location-4.jpg', title: 'Nombre de edificio o casa' },
        { imgUrl: 'public/images/pages/account/location-5.jpg', title: 'Nombre de edificio o casa' },
        { imgUrl: 'public/images/pages/account/location-6.jpg', title: 'Nombre de edificio o casa' },
      ]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
