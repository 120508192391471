<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Activos personales</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="box-white-s1 results-t2-section">
      <div class="row">
        <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option value="a">Mayor a menor</b-form-select-option>
                <b-form-select-option value="b">Menor a mayor</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div>

        <div class="col-sm-6 col-xl-4 xxl-4 content-sample-2" v-for="(o, oInx) in obras" :key="'oInx-'+oInx">
          <div class="box">
            <div class="col-12 placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+o.imgUrl+')' }"></div>

            <div class="col-12 col-info">
              <h5 class="name">{{ o.title }}</h5>
              <h6 class="price">$1,000,000.00 MXN</h6>

              <p>Arte</p>
              <p><a href="">Descargar factura</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,
      category: 0,

      obras: [
        { imgUrl: 'public/images/pages/account/asset-1.jpg', title: 'Nombre de pintura' },
        { imgUrl: 'public/images/pages/account/asset-2.jpg', title: 'Nombre de pintura' },
        { imgUrl: 'public/images/pages/account/asset-3.jpg', title: 'Nombre de pintura' },
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
