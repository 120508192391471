<template lang="html">
  <div id="dashboard-home-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Periodo del 12 al 18 de Noviembre</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section>

    <section class="summary-section">
      <div class="row">
        <div class="col-md-4 col-summary">
          <div class="box-summary box-white-s1">
            <div class="box-title">Patrimonio neto</div>

            <div class="box-info">
              <div class="inside">
                <h4 class="box-price">$25,000,000.00 MXN</h4>

                <div class="box-data">
                  <div class="table">
                    <div class="t-row">
                      <div class="t-cell c-text">Variación mensual</div>
                      <div class="t-cell c-num">
                        3.5 y.
                        <i class="far fa-long-arrow-up icon"></i>
                        <!-- <i class="far fa-long-arrow-down icon"></i> -->
                      </div>
                    </div>

                    <div class="t-row">
                      <div class="t-cell c-text">Variación anterior</div>
                      <div class="t-cell c-num">$22,000,000.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-summary">
          <div class="box-summary box-white-s1">
            <div class="box-title">Portafolio financiero</div>

            <div class="box-info">
              <div class="inside">
                <div class="box-price">
                  <div class="n">$8,000,000.00</div>

                  <div class="box-currency-s1">
                    <span v-bind:class="{ active : currency == 'mxn' }" @click="currency = 'mxn'">MXN</span>
                    <span v-bind:class="{ active : currency == 'usd' }" @click="currency = 'usd'">USD</span>
                  </div>
                </div>

                <div class="box-data">
                  <div class="table">
                    <div class="t-row">
                      <div class="t-cell c-text">Variación mensual</div>
                      <div class="t-cell c-num">
                        3.5 y.
                        <i class="far fa-long-arrow-up icon"></i>
                        <!-- <i class="far fa-long-arrow-down icon"></i> -->
                      </div>
                    </div>

                    <div class="t-row">
                      <div class="t-cell c-text">Variación anterior</div>
                      <div class="t-cell c-num">$7,000,000.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-summary">
          <div class="box-summary box-white-s1">
            <div class="box-title">Pasivos</div>

            <div class="box-info">
              <div class="inside">
                <div class="box-price">
                  <div class="n">$1,000,000.00 MXN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="maps-charts-section">
      <div class="row">
        <div class="col-lg-6 col-data">
          <div class="box-data box-white-s1">
            <div class="box-title">
              <div class="col b-left">
                <h5>Desglose patrimonio</h5>
              </div>

              <div class="col b-right">
                <a class="t-150 mini-btn">Descargar</a>
              </div>
            </div>

            <div class="box-content">
              <ccv-pie-chart :data='data' :options='options'></ccv-pie-chart>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-data">
          <div class="box-data box-white-s1">
            <div class="box-title">
              <div class="col b-left">
                <h5>Distribución por región</h5>
              </div>

              <div class="col b-right">
                <a class="t-150 mini-btn">Descargar</a>
              </div>
            </div>

            <div class="box-content w-map">
              <!-- NO QUITAR ESTA DIV -->
              <div id="dirtyDiv"></div>

              <svg-map :map="World"/>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
// == Imports para el mapa ( CON VUE: https://github.com/VictorCazanave/vue-svg-map | SIN VUE: https://github.com/StephanWagner/svgMap) ==
// Para saber el "code" de cada pais, se usa el "ISO 3166-1 alpha-2" (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
import { SvgMap } from "vue-svg-map";
import World from "@svg-maps/world";
// == ==
export default {
	components: {
		SvgMap
	},

  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Values necesarios para los gráficos ==
      data: [
        {
          "group": "Bienes raices",
          "value": 20
        },
        {
          "group": "Finanzas",
          "value": 40
        },
        {
          "group": "Empresas",
          "value": 25
        },
        {
          "group": "Cheques",
          "value": 45
        },
      ],

      options: {
        // "title": "Pie",
        "resizable": true,
        "height": "400px",
        "legend": {
          "alignment": "center"
        },
        "pie": {
          "alignment": "center"
        },
      },
      // == ==

      // Value necesario para el mapa (cuidado, NO MOVER)
      World,
      countriesArr: ['mx','ca', 'us' ,'br', 'cn', 'jp'], // Codigos de paises segun el ISO 3166-1 alpha-2
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },

    fillCountries() { // Debe ir en "mounted()" o despues
      let ids = this.countriesArr.map(function(country) { // Agregar el # para reconocer el id a poner la clase css
        return '#'+country
      });
      ids = ids.toString();

      const elem = document.getElementById("dirtyDiv");
      elem.insertAdjacentHTML("beforeend", `
        <style>
          ${ids}{
            fill:#001f44 !important; stroke: #001f44 !important;
          }
        </style>`
      );
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Dashboard';
    this.fillCountries();
  }
}
</script>

<!-- <style src="vue-svg-map/dist/index.css"></style> -->
