<template lang="html">
  <div id="content-t1-page">

    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Bienes raíces</h2>
        </div>

        <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 outline-black mr-2">Exportar</button>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div>
      </div>
    </section>

    <section class="px-0 results-section">
      <div class="row">
        <div class="col-xl-8 mb-0 content-sample-1 xl">
          <div class="box box-white-s1">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/account/location-2.jpg)' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col-12 box-title">
                    <h5 class="mb-2 name">Nombre de edificio o casa</h5>

                    <p class="txt-black descr">
                      Diamante #2680, Bosques de la Victoria, Zapopan, Jalisco.
                    </p>
                  </div>

                  <div class="box-icons-s1">
                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-metters.png" /></div>
                      <span class="text">120m2</span>
                    </div>

                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-rooms.png" /></div>
                      <span class="text">3 cuartos</span>
                    </div>

                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-bathroom.png" /></div>
                      <span class="text">3 cuartos</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-txt">
                  <p class="h6">
                    Descripción o información importante necesaria del inmueble que es importate saber o conocer.
                  </p>
                </div>

                <div class="col-4 col-txt">
                  <h5>Construido:</h5>
                  <h4 class="f-w-600 txt-black">2017</h4>
                </div>

                <div class="col-4 col-txt">
                  <h5>Cochera:</h5>
                  <h4 class="f-w-600 txt-black">1 auto</h4>
                </div>

                <div class="col-4 col-txt">
                  <h5>ID de propiedad:</h5>
                  <h4 class="f-w-600 txt-black">OIEJ8372901</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 mt-xl-0 col-xl-4 col-extra-info">
          <div class="d-block h-100 p-3 box-white-s1">
            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Valor comercial</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$12,000,000.00 MXN</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Valor catastral</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$6,000,000.00 MXN</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Tipo de operación</h5>
              <h4 class="f-w-700 txt-l-h-1-2">Renta</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Estado</h5>
              <h4 class="f-w-700 txt-l-h-1-2">Disponible</h4>
            </div>

            <div class="d-block">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Dividendo</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$100,00.00</h4>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4 col-charts">
          <div class="box box-white-s1">
            <div class="text-center box-title">
              <h5>Dividendos actuales vs Año anterior</h5>
            </div>

            <div class="box-content">
              <ccv-line-chart :data='data' :options='options'></ccv-line-chart>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Graficos ==
      data: [
        // -- 2021 --
        {
          "group": "Año 2021",
          "key": "Enero",
          "value": 34200
        },
        {
          "group": "Año 2021",
          "key": "Febrero",
          "value": 23500
        },
        {
          "group": "Año 2021",
          "key": "Marzo",
          "value": 53100
        },
        {
          "group": "Año 2021",
          "key": "Abril",
          "value": 42300
        },
        {
          "group": "Año 2021",
          "key": "Mayo",
          "value": 32300
        },
        {
          "group": "Año 2021",
          "key": "Junio",
          "value": 43300
        },
        {
          "group": "Año 2021",
          "key": "Julio",
          "value": 35100
        },
        {
          "group": "Año 2021",
          "key": "Agosto",
          "value": 44900
        },
        {
          "group": "Año 2021",
          "key": "Septiembre",
          "value": 45900
        },
        {
          "group": "Año 2021",
          "key": "Octubre",
          "value": 62900
        },
        // -- --

        // -- 2022 --
        {
          "group": "Año 2022",
          "key": "Enero",
          "value": 48200
        },
        {
          "group": "Año 2022",
          "key": "Febrero",
          "value": 33500
        },
        {
          "group": "Año 2022",
          "key": "Marzo",
          "value": 43100
        },
        {
          "group": "Año 2022",
          "key": "Abril",
          "value": 22300
        },
        {
          "group": "Año 2022",
          "key": "Mayo",
          "value": 32300
        },
        {
          "group": "Año 2022",
          "key": "Junio",
          "value": 28300
        },
        {
          "group": "Año 2022",
          "key": "Julio",
          "value": 35100
        },
        {
          "group": "Año 2022",
          "key": "Agosto",
          "value": 48900
        },
        {
          "group": "Año 2022",
          "key": "Septiembre",
          "value": 50900
        },
        {
          "group": "Año 2022",
          "key": "Octubre",
          "value": 76900
        },
        // -- --
      ],

      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Meses",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "title": "Años",
            "mapsTo": "value",
            "scaleType": "linear"
          }
        },
        "height": "400px",
      }
      // == ==
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
  }
}
</script>
