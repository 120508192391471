<template lang="html">
  <div id="access-page">

    <section class="main-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-lg-9 col-xl-7 col-content">
            <div class="box-logo">
              <img src="public/images/logo.svg" alt="G Capital Group">
            </div>

            <div class="box-form">
              <h4 class="pb-2 mb-4 text-center">Accede a tu cuenta</h4>

              <b-form @submit="onSubmit">
                <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                  <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="cus-f-group-1 col-12" label="Contraseña">
                  <b-form-input type="password" v-model="form.password" required placeholder=""></b-form-input>
                </b-form-group>

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-checkbox
                        class="custom-checkbox-s1"
                        v-model="form.checked"
                        name="checkbox-1"
                        value="accepted"
                        :unchecked-value="null">
                        Recuerdame
                      </b-form-checkbox>
                    </div>

                    <div class="mt-4 mt-sm-0 col-sm-6 text-center text-sm-right order-5 order-sm-0">
                      <router-link to="/restaurar-contrasena">Olvidé mi contraseña <i class="fas fa-key ml-1 small"></i></router-link>
                    </div>

                    <div class="col-12 mt-3 text-center">
                      <b-button type="submit" class="btn-s1 bg-white">Entrar</b-button>
                    </div>

                    <div class="col-12 mt-2 mt-sm-3 text-center order-6">
                      <p>¿No tienes acceso? <router-link class="f-w-600" to="/registrarse">Obtén acceso</router-link></p>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$router.push('/autenticacion');
    },
  }
}
</script>
